import React, { useEffect, useRef, useState } from 'react'
import Select, { components } from 'react-select'
import {
  Accordion,
  Button,
  Card,
  Col,
  Row,
  Spinner,
  Tab,
  Table,
  Tabs,
  Form,
  useAccordionButton,
  CardGroup,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import moment, { Moment } from 'moment'
import { CSVLink } from 'react-csv'
import { BsBarChartLine, BsTable } from 'react-icons/bs'

import Device, { DeviceIDTypes } from '@context/device/model/device'
import { useDevicesWaterUsage } from '@data/waterUsage/waterUsage'
import {
  hasPermissions,
  roundToTwo,
  filterDevicesDueTo30DaysReport,
  formatCurrency,
  convertToUnitOfVolume,
  calculateCo2Produced,
} from '@common/utils/helperFunctions'
import { useAlertDispatch, useAlertState } from '@context/alert/context/alert.context'
import { AlertStat } from '@context/alert/model/alert.model'
import { useAuthState } from '@context/auth/context/auth.context'
import DateRangeComponent from '../Calendar/DateRangeSelector'
import { Bar } from 'react-chartjs-2'
import { CheckCircle2 } from 'lucide-react'
import { getMultipleDevicesDailyDatasets, getChartOptions } from '../../WaterUsage/helper-functions'
import { COLUMNS } from '../../WaterUsage/constants'
import jsPDF from 'jspdf'
import { Chart as ChartJS } from 'chart.js'
import { buildPDFReport } from './helper-functions'
import { IReportTotalProps, IWaterUsageProps, IUsageDay } from './interfaces'
import { useDeviceState, useDeviceDispatch } from '@context/device/context/device.context'
import { useAccountState } from '@context/account/context/account.context'
import ESGEmailModal from './ESGEmailModal'
import { useUserState } from '@context/user/context/user.context'
import { deviceWaterUsage } from '@data/waterUsage/model/waterUsage.model'
import { UOM_OPTIONS } from '../../DeviceManagement/DeviceInformationManager/constants'

import 'react-datepicker/dist/react-datepicker.css'
import './ESG.scss'

const TODAY: Moment = moment()
const BACK_30_DAYS = TODAY.clone().subtract(30, 'days').toDate()
const MAX_SELECTABLE_DEVICES = 20

/*
  0: Not asked
  1: Asked, not ready
  2: Ready
*/
// type GenerateReportState = 0 | 1 | 2;

const ESGReportingComponent: React.FC<IWaterUsageProps> = ({ devices }: IWaterUsageProps) => {
  const { permissions } = useAuthState()
  const { selectedDevices } = useDeviceState()
  const { selectedAccounts } = useAccountState()
  const { userInfo } = useUserState()

  const { setSelectedDevices, updateDeviceData } = useDeviceDispatch()
  const { filteredQueue, alertStatsMultipleDevices, historical } = useAlertState()
  const { loadMultipleDevicesStats, loadHistoricalAlerts } = useAlertDispatch()

  const [csvData, setCsvData] = useState<any>([])
  const [fileName, setFileName] = useState('esg_report')
  const [dailyDatasets, setDailyDatasets] = useState<any>()
  const [activeAlerts, setActiveAlerts] = useState<number>(0)
  const [generateReportState, setGenerateReportState] = useState(0)
  const [totalUsage, setTotalUsage] = useState<null | number>(null)
  const [totalUsageVolume, setTotalUsageVolume] = useState<null | string>(null)
  const [fromDate, setFromDate] = useState<Date | null>(TODAY.clone().subtract(30, 'days').toDate())
  const [toDate, setToDate] = useState<Date | null>(TODAY.clone().toDate())
  const [didSomethingChange, setDidSomethingChange] = useState(true)
  const [alertStatsAllDevice, setAlertStatsAllDevice] = useState<number>(0)
  const [selectedDevicesIds, setSelectedDevicesIds] = useState<string[]>([])
  const [alertStatsPeriodDevice, setAlertStatsPeriodDevice] = useState<number>(0)
  const [selectedDevicesEarliestInstallDate, setSelectedDevicesEarliestInstallDate] = useState<
    string | null
  >(null)
  const [totalUsageByCurrency, setTotalUsageByCurrency] = useState<
    Record<string, { totalUsage: number; totalCost: number; devices: number }>
  >({})

  const [displaySendEmailModal, setDisplaySendEmailModal] = useState(false)
  const [pdfBase64, setPdfBase64] = useState('')
  const [selectedUom, setSelectedUom] = useState(userInfo.preferences?.uom || 'Liters')

  const alertsShowInProgress = hasPermissions(permissions, ['READ:ALERTS:USAGE:IN_PROGRESS'])

  const tableRef = useRef(null)
  const chartRef = useRef<ChartJS<'bar', number[], string>>(null)

  const devicesDueTo30DaysReport = filterDevicesDueTo30DaysReport(devices)

  const co2Total = totalUsage
    ? calculateCo2Produced(selectedUom, totalUsage, selectedDevices)
    : null

  const from = moment(fromDate).format('YYYY-MM-DDT00:00:00')
  const to = moment(toDate).format('YYYY-MM-DDT23:59:59')
  const queryPeriod = {
    deviceIds: selectedDevicesIds,
    from: from,
    to: to,
    uom: selectedUom,
  }

  const {
    data: multipleDeviceUsage,
    isLoading,
    isFetching,
    refetch,
  } = useDevicesWaterUsage(queryPeriod)

  useEffect(() => {
    setSelectedDevices([])
  }, [selectedAccounts, devices])

  useEffect(() => {
    loadHistoricalAlerts()
  }, [])

  useEffect(() => {
    if (selectedDevices.length > 0 && multipleDeviceUsage) {
      const multipleDevicesDailyDatasets = getMultipleDevicesDailyDatasets(
        multipleDeviceUsage?.usage,
      )
      setDailyDatasets(multipleDevicesDailyDatasets)

      if (multipleDeviceUsage?.totalUsage) {
        const totalUsageConverted = convertToUnitOfVolume(
          selectedUom,
          multipleDeviceUsage.totalUsage,
        )

        setTotalUsage(multipleDeviceUsage.totalUsage)
        setTotalUsageVolume(totalUsageConverted)
      }

      if (multipleDeviceUsage?.totalUsageByDevice) {
        const totalUsageByCurrency = calculateTotalUsageByCurrency(
          selectedDevices,
          multipleDeviceUsage.totalUsageByDevice,
        )

        setTotalUsageByCurrency(totalUsageByCurrency)
      }
    }
  }, [multipleDeviceUsage])

  useEffect(() => {
    if (Object.keys(alertStatsMultipleDevices).length === 0) return

    const periodAlerts: AlertStat[] = alertStatsMultipleDevices.period
    const statsPeriodTotal = periodAlerts.reduce((acc: number, stat: AlertStat) => {
      return acc + stat.alert_count
    }, 0)
    setAlertStatsPeriodDevice(statsPeriodTotal)
  }, [alertStatsMultipleDevices])

  useEffect(() => {
    setDidSomethingChange(true)
    setTotalUsage(null)

    if (selectedDevices.length > 0) {
      const selectedDeviceIds = selectedDevices.map((obj) => obj.deviceId)
      setSelectedDevicesIds(selectedDeviceIds)
      const selectedDevicesDlId = selectedDevices.map((alert) => alert.dlId)
      const selectedDevicesAlerts = filteredQueue.filter((alert) =>
        selectedDevicesDlId.includes(alert.dlId),
      )
      setActiveAlerts(selectedDevicesAlerts.length)

      let earliestDate = Infinity

      for (const item of selectedDevices) {
        const installDate = new Date(item.deviceSettings.installDate).getTime()

        if (installDate < earliestDate) {
          earliestDate = installDate
        }
      }

      setSelectedDevicesEarliestInstallDate(
        earliestDate !== Infinity ? moment(earliestDate).format('YYYY-MM-DDTHH:mm:ss') : null,
      )

      const from = moment(fromDate).format('DD-MM-YYYY')
      const to = moment(toDate).format('DD-MM-YYYY')

      if (selectedDevices.length === 1) {
        setFileName(`${selectedDevices[0].deviceName}-${from}_${to}_esg_report`)
      } else {
        setFileName(`ESG_REPORT_multiple_devices-${from}_${to}`)
      }
    }
  }, [selectedDevices])

  useEffect(() => {
    setDidSomethingChange(true)
  }, [fromDate, toDate, selectedUom])

  const askForData = () => {
    const from = moment(fromDate).format('YYYY-MM-DDT00:00:00')
    const to = moment(toDate).format('YYYY-MM-DDT23:59:59')

    if (selectedDevices.length > 0 && selectedDevices.length <= MAX_SELECTABLE_DEVICES) {
      const queryPeriod = {
        deviceIds: selectedDevicesIds,
        from: from,
        to: to,
        uom: selectedUom,
      }

      refetch()

      const statsQuery = [{ ...queryPeriod, type: 'period' }]

      loadMultipleDevicesStats(statsQuery)
      sumUsageAlertsTotalForDeviceIds()

      setDidSomethingChange(false)
    }
  }

  const calculateTotalUsageByCurrency = (
    devices: Device[],
    totalUsage: Record<string, number>,
  ): Record<string, { totalUsage: number; totalCost: number; devices: number }> => {
    const totalUsageByCurrency: Record<
      string,
      { totalUsage: number; totalCost: number; devices: number }
    > = {}

    devices.forEach((device) => {
      const { deviceSettings, deviceId } = device
      const { currency, cost } = deviceSettings

      if (totalUsageByCurrency[currency]) {
        totalUsageByCurrency[currency].totalUsage += totalUsage[deviceId] || 0
        totalUsageByCurrency[currency].totalCost =
          Number(totalUsageByCurrency[currency].totalCost) + Number(cost)
        totalUsageByCurrency[currency].devices += 1
      } else {
        totalUsageByCurrency[currency] = {
          totalUsage: totalUsage[deviceId],
          totalCost: cost,
          devices: 1,
        }
      }
    })

    return totalUsageByCurrency
  }

  const sumUsageAlertsTotalForDeviceIds = () => {
    let totalAlertsOfSelectedDevices = 0

    for (const deviceData of historical) {
      if (selectedDevicesIds.includes(deviceData.deviceId)) {
        const tier1Length = deviceData.tier1.length
        const tier2Length = deviceData.tier2.length
        const tier3Length = deviceData.tier3.length

        totalAlertsOfSelectedDevices += tier1Length + tier2Length + tier3Length
      }
    }

    setAlertStatsAllDevice(totalAlertsOfSelectedDevices)
  }

  function summarizeFlowData(usageData: deviceWaterUsage[][]): any {
    const flowSummary: any = {}

    usageData.forEach((obj) => {
      const usageData: deviceWaterUsage[] = obj
      usageData.forEach((entry: deviceWaterUsage) => {
        const { date, hour, flow } = entry
        const dateKey = date.split('T')[0]

        if (!flowSummary[dateKey]) {
          flowSummary[dateKey] = { date: dateKey, totalFlow: 0 } as IUsageDay
        }

        if (flow !== null && !isNaN(flow)) {
          flowSummary[dateKey].totalFlow += flow
          flowSummary[dateKey][`totalFlowHour${hour}`] =
            (flowSummary[dateKey][`totalFlowHour${hour}`] || 0) + flow
        } else {
          if (
            !isNaN(flowSummary[dateKey][`totalFlowHour${hour}`]) ||
            flowSummary[dateKey][`totalFlowHour${hour}`] !== null
          ) {
            flowSummary[dateKey][`totalFlowHour${hour}`] =
              flowSummary[dateKey][`totalFlowHour${hour}`]
          } else {
            flowSummary[dateKey][`totalFlowHour${hour}`] = null
          }
        }
      })
    })

    return flowSummary
  }

  const getRows = (waterUsage: any) => {
    const groupedByDay = summarizeFlowData(waterUsage)

    return Object.values(groupedByDay).map((usage: any, idx: number) => {
      const renderHourCell = (hour: number) => {
        const value = usage[`totalFlowHour${hour}`]
        return value ? (value !== 0 ? roundToTwo(value) : 0) : '-'
      }
      return (
        <tr key={idx}>
          <td style={{ position: 'sticky', left: 0 }}> {usage.date} </td>
          <td> {roundToTwo(usage.totalFlow)}</td>
          {[...Array(24).keys()].map((hour) => (
            <td key={hour}>{renderHourCell(hour)}</td>
          ))}
        </tr>
      )
    })
  }

  const getHeaders = () => {
    const periodColumns: Array<any> = [
      {
        name: 'Date',
        key: 'date',
        width: '120px',
      },
      {
        name: `Usage in ${selectedUom}`,
        key: 'usage',
        width: '120px',
      },
    ]

    const headersObj = [...periodColumns, ...COLUMNS]

    return headersObj.map((header: any) => (
      <th style={{ width: header.width ?? '80px' }} key={header.key}>
        {header.name}
      </th>
    ))
  }

  const generateCsvFile = () => {
    if (!selectedDevices.length) return
    const groupedByDayArray = Object.values(
      summarizeFlowData(multipleDeviceUsage?.usage as any),
    ).map((usage: any, idx: number) => {
      return Object.values(usage)
    })

    const selectedDevicesData = []

    for (const device of selectedDevices) {
      const deviceData = [
        ['Device Name', device.deviceName],
        ['Device ID', device.deviceId],
        // device.deviceInfo.addressLine1 ? ['Address Line 1', device.deviceInfo.addressLine2] : [],
        // device.deviceInfo.addressLine2 ? ['Address Line 2', device.deviceInfo.addressLine2] : null,
        // device.deviceInfo.town ? ['Town', device.deviceInfo.town] : null,
        // device.deviceInfo.county ? ['County', device.deviceInfo.county] : null,
        // device.deviceInfo.postcode ? ['Postcode', device.deviceInfo.postcode] : null,
        // device.deviceInfo.country ? ['Country', device.deviceInfo.country] : null,
        [''],
      ]

      selectedDevicesData.push(deviceData)
    }

    const totalCostData = Object.entries(totalUsageByCurrency).map(([currency, data]) => {
      return [
        `Total Cost in ${currency} ${
          selectedDevices.length > 1 ? `(${data.devices} devices)` : ''
        }`,
        formatCurrency(roundToTwo(roundToTwo(data.totalUsage / 1000) * data.totalCost), currency),
      ]
    })

    const data = [
      [
        'Date Range',
        `${moment(fromDate).format('DD/MM/YYYY')} - ${moment(toDate).format('DD/MM/YYYY')}`,
      ],
      [''],
      ...selectedDevicesData.flat(1),
      [
        'Total Water Consumed',
        totalUsageVolume ? totalUsageVolume.split(' ')[0] : null,
        'unit',
        totalUsageVolume?.split(' ')[1],
      ],
      [
        'Total Co2 Produced',
        totalUsage ? calculateCo2Produced(selectedUom, totalUsage, selectedDevices).value : null,
        'unit',
        'kg',
      ],
      ...totalCostData,
      [''],
      ['Usage breakdown'],
      ['Date', `Usage in ${selectedUom}`, ...Object.values(COLUMNS).map((c) => c.name)],
      ...groupedByDayArray,
    ].filter((v) => v !== null)
    setCsvData(data)
  }

  const downloadPdfDocument = (sendReportByEmail = false) => {
    if (selectedDevices.length) {
      const tableHTML = tableRef.current
      if (!chartRef) return
      if (!chartRef.current) return
      if (!tableHTML) return
      const chartImage = chartRef.current.toBase64Image()

      const totalCostByCurrency = Object.entries(totalUsageByCurrency).map(([currency, data]) => {
        return `${formatCurrency(
          roundToTwo(roundToTwo(data.totalUsage / 1000) * data.totalCost),
          currency,
        )} ${selectedDevices.length > 1 ? `(${data.devices} devices)` : ''}`
      })

      const reportTotals: IReportTotalProps = {
        usageTotal: totalUsage ? totalUsage : null,
        totalVolume: totalUsageVolume && totalUsageVolume,
        co2Total: `${co2Total?.value + ' ' + co2Total?.unit}`,
        activeAlerts: activeAlerts,
        periodAlerts: alertStatsPeriodDevice,
        totalAlerts: alertStatsAllDevice,
        totalCostByCurrency,
      }
      buildPDFReport(
        selectedDevices,
        fromDate,
        toDate,
        chartImage,
        tableHTML,
        reportTotals,
        selectedUom,
      ).then((pdf: jsPDF) => {
        if (!sendReportByEmail) {
          setGenerateReportState(2)
          pdf.save(fileName)
        } else {
          generateCsvFile()
          setPdfBase64(pdf.output('datauristring'))
          setDisplaySendEmailModal(true)
        }
      })
    }
  }

  const ValueContainer = (containerProps: any) => {
    if (isLoading || isFetching) {
      return <> Loading...</>
    }

    if (selectedAccounts.length === 0) {
      return <span className="ml-3">Please select an account first...</span>
    }

    return (
      <components.ValueContainer {...containerProps} className={'d-flex'}>
        Devices ({selectedDevices.length} / {devices.length}){containerProps.children}
      </components.ValueContainer>
    )
  }

  const Option = (containerProps: any) => {
    return (
      <components.Option {...containerProps}>
        <Form.Check checked={containerProps.isSelected} readOnly label={containerProps.children} />
      </components.Option>
    )
  }

  const optionStyles = (styles: any, { isFocused, isDisabled }: any) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#eee' : null,
      color: isDisabled ? '#dedede' : '#333',
      cursor: 'pointer',
    }
  }

  const MenuList = (props: any) => {
    return (
      <components.MenuList {...props}>
        {devices.length > MAX_SELECTABLE_DEVICES ? (
          <div className="ml-3 mt-2 mb-2">
            **{' '}
            <span className="font-weight-bold text-decoration-underline">
              {`You can select up to ${MAX_SELECTABLE_DEVICES} devices`}
            </span>{' '}
            **
          </div>
        ) : selectedDevices.length > 0 ? (
          <button className="btn btn-link" onClick={() => setSelectedDevices([])}>
            deselect all
          </button>
        ) : (
          <button className="btn btn-link" onClick={() => setSelectedDevices(devices)}>
            select all
          </button>
        )}

        {props.children}
      </components.MenuList>
    )
  }
  const CustomToggle = ({ children, eventKey, callback }: any) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey))

    return <Button onClick={decoratedOnClick}>{children}</Button>
  }

  const handleCompleteReport = async (device: Device) => {
    const updatedDevice = { ...device }
    updatedDevice.deviceSettings.report30Day = true
    if (device.dUUID) {
      await updateDeviceData(device?.dUUID, DeviceIDTypes.dUUID, updatedDevice)
    }
  }

  return (
    <div className="esg-report">
      <Row className="main-content">
        <Col sm={6}>
          <h1>ESG Summary</h1>
          <Row>
            <div className="section-header">
              <div className="">Device:</div>
            </div>
            <Select
              styles={{ option: optionStyles }}
              components={{ ValueContainer, MenuList, Option }}
              className="basic-multi-select"
              value={selectedDevices}
              onChange={(options: any) => setSelectedDevices([...options])}
              options={devices}
              isMulti
              isOptionDisabled={() => selectedDevices.length === MAX_SELECTABLE_DEVICES}
              name="devices"
              classNamePrefix="select"
              getOptionValue={(option) => option.deviceId}
              getOptionLabel={(option) => `${option.deviceName}`}
              isClearable={false}
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              isLoading={isLoading || devices.length === 0}
              isDisabled={isLoading || devices.length === 0}
            />
          </Row>
          <Row>
            <DateRangeComponent
              from={BACK_30_DAYS}
              to={TODAY.clone().toDate()}
              fromCallback={setFromDate}
              toCallback={setToDate}
              minDate={
                selectedDevicesEarliestInstallDate
                  ? moment(selectedDevicesEarliestInstallDate).toDate()
                  : null
              }
            />
          </Row>
          <Row className="mb-3 w-50">
            <Form.Group as={Col} controlId="expected-percentage-savings">
              <Form.Label>Unit of measure</Form.Label>
              <Select
                classNamePrefix="select"
                value={{
                  value: selectedUom,
                  label: selectedUom,
                }}
                onChange={(e: any) => setSelectedUom(e.value)}
                options={UOM_OPTIONS.map((uom) => {
                  return {
                    value: uom,
                    label: uom === userInfo.preferences?.uom ? `${uom} (your set preference)` : uom,
                  }
                })}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => `${option.label}`}
                isClearable={false}
              />
            </Form.Group>
          </Row>
          <div className="get-data-button">
            <Button
              variant="primary"
              onClick={askForData}
              disabled={selectedDevices.length === 0 || !didSomethingChange}
            >
              {isLoading || isFetching ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{' '}
                  <span>Getting Data...</span>
                </>
              ) : (
                <>Get Data</>
              )}
            </Button>
          </div>
        </Col>
        {hasPermissions(permissions, ['ACCOUNT:ADMIN:SMARTFLOW']) &&
        devicesDueTo30DaysReport.length > 0 ? (
          <Col sm={6} className="devicesDue d-flex flex-column align-items-center">
            <div className="h4 mt-3">
              <strong>
                {`${devicesDueTo30DaysReport.length} devices are due to a 30 day report`}
              </strong>
            </div>
            <ul>
              {devicesDueTo30DaysReport.map((device) => (
                <div className="d-flex align-items-top-center" key={device.deviceId}>
                  <li>{`${device.deviceName} (${moment(
                    device.deviceSettings.monitoringStartDate,
                  ).format('DD-MM-YYYY')} monitoring start date)`}</li>
                  <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props: any) => <Tooltip {...props}>Mark as completed</Tooltip>}
                    placement="bottom"
                  >
                    <CheckCircle2
                      role="button"
                      size={20}
                      color={'green'}
                      className="ml-2 mt-1"
                      onClick={() => handleCompleteReport(device)}
                    />
                  </OverlayTrigger>
                </div>
              ))}
            </ul>
          </Col>
        ) : (
          ''
        )}
      </Row>
      <div className="data-container">
        {isLoading || isFetching ? (
          <div>Loading...</div>
        ) : (
          totalUsage &&
          !isLoading &&
          !isFetching &&
          !didSomethingChange && (
            <Col className="data">
              <Row>
                <Col>
                  <Row>
                    <CardGroup
                      className="gap-3"
                      style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fill, 12.5rem)',
                      }}
                    >
                      {selectedDevices.length > 0 &&
                        selectedDevices.map((device) => {
                          return (
                            <Card className="shadow" key={device.deviceId}>
                              <Card.Body>
                                {device?.deviceName ? <h4>{device?.deviceName}</h4> : null}
                              </Card.Body>
                            </Card>
                          )
                        })}
                    </CardGroup>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col lg={10}>
                  <Row>
                    <Col lg={4}>
                      <Card className="shadow">
                        <Card.Body>
                          <h4>
                            <i className="fas fa-fw fa-dollar-sign mr-3"></i>Total Cost
                          </h4>
                          <div>
                            {Object.entries(totalUsageByCurrency).map(([currency, data]) => {
                              return (
                                <b className="ml-5 d-flex" key={currency}>
                                  {formatCurrency(
                                    roundToTwo(roundToTwo(data.totalUsage / 1000) * data.totalCost),
                                    currency,
                                  )}
                                  {`${
                                    selectedDevices.length > 1 ? `(${data.devices} devices)` : ''
                                  }`}
                                </b>
                              )
                            })}
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col lg={4}>
                      <Card className="shadow">
                        <Card.Body>
                          <h4>
                            <i className={'fas fa-fw fa-water mr-3'}></i>Total Water Consumed
                          </h4>
                          <b>
                            <span className="ml-5">{totalUsageVolume}</span>
                          </b>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col lg={4}>
                      <Card className="shadow">
                        <Card.Body>
                          <h4>
                            <i className="fas fa-fw fa-cloud mr-3"></i>Total CO<sub>2</sub> Produced
                          </h4>
                          <b>
                            <span className="ml-5">
                              {`${co2Total?.value + ' ' + co2Total?.unit}`}
                            </span>
                          </b>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Card className="shadow">
                        <Card.Body>
                          <h4>
                            <i className="fas fa-fw fa-exclamation-triangle mr-3"></i>Active Usage
                            Alerts
                          </h4>
                          <b>
                            <span className="ml-5">{activeAlerts}</span>
                          </b>
                          {alertsShowInProgress && activeAlerts > 0 ? (
                            <NavLink to="/alert-queue">view</NavLink>
                          ) : null}
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col lg={4}>
                      <Card className="shadow">
                        <Card.Body>
                          <h4>
                            <i className="fas fa-fw fa-exclamation-triangle mr-3"></i>Usage Alerts
                          </h4>
                          <b>
                            <span className="ml-5">{alertStatsPeriodDevice}</span>
                          </b>{' '}
                          ({moment(fromDate).format('DD/MM/YYYY')} to{' '}
                          {moment(toDate).format('DD/MM/YYYY')})
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col lg={4}>
                      <Card className="shadow">
                        <Card.Body>
                          <h4>
                            <i className="fas fa-fw fa-exclamation-triangle mr-3"></i>Usage Alerts
                            Total
                          </h4>
                          <b>
                            <span className="ml-5">{alertStatsAllDevice}</span>
                          </b>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={'my-butts'}>
                    <CSVLink
                      data={csvData}
                      onClick={generateCsvFile}
                      className="btn btn-primary mr-lg-2"
                      filename={`${fileName}.csv`}
                    >
                      Generate CSV Report
                    </CSVLink>
                    <Button onClick={() => downloadPdfDocument()}>
                      <div>
                        {generateReportState === 1 ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span>Generating...</span>
                          </>
                        ) : (
                          <>Generate PDF Report</>
                        )}
                      </div>
                    </Button>
                    {selectedDevices.length < 2 && (
                      <Button className="ml-3" onClick={() => downloadPdfDocument(true)}>
                        Send Reports by email
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Card.Header>
                        <CustomToggle eventKey="0">
                          <span className="fas fa-hand-holding-water fa-lg mr-2"></span> Show/Hide
                          Usage
                        </CustomToggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <div className="data-visualizations">
                            <Tabs
                              transition={false}
                              id="controlled-tab-example"
                              // activeKey={selectedPeriod}
                              // onSelect={(k: any) => setSelectedPeriod(k)}
                            >
                              <Tab eventKey={'usage'} title={<BsBarChartLine />}>
                                <article
                                  id="canvas-container-id-usage"
                                  className="canvas-container"
                                >
                                  <Bar
                                    width={2500}
                                    height={600}
                                    data={dailyDatasets}
                                    ref={chartRef}
                                    options={getChartOptions('daily', selectedUom)}
                                  />
                                </article>
                              </Tab>
                              <Tab eventKey={'table'} title={<BsTable />}>
                                <article
                                  id="canvas-container-id-table"
                                  className="canvas-container"
                                >
                                  <div className="table-container">
                                    <Table id="usage-table" ref={tableRef} responsive>
                                      <thead>
                                        <tr>{getHeaders()}</tr>
                                      </thead>
                                      <tbody>
                                        {multipleDeviceUsage?.usage &&
                                          getRows(multipleDeviceUsage.usage)}
                                      </tbody>
                                    </Table>
                                  </div>
                                </article>
                              </Tab>
                            </Tabs>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </Col>
              </Row>
            </Col>
          )
        )}
      </div>

      {displaySendEmailModal && (
        <ESGEmailModal
          selectedDevice={selectedDevices[0]}
          onHide={() => setDisplaySendEmailModal(false)}
          pdfAttachment={pdfBase64}
          csvAttachment={csvData}
          devicesDueTo30DaysReport={devicesDueTo30DaysReport}
        />
      )}
    </div>
  )
}

export default ESGReportingComponent
