import SideBarMenuBlock from './SideBarMenuBlock/SideBarMenuBlock'
import { NavLink } from 'react-router-dom'
import { useLayoutDispatch, useLayoutState } from '@context/layout/context/layout.context'
import { useAuthState } from '@context/auth/context/auth.context'
import { useUserState } from '@context/user/context/user.context'
import { hasPermissions, filterDevicesDueTo30DaysReport } from '@common/utils/helperFunctions'
import { useDeviceState } from '@context/device/context/device.context'

function SideBar() {
  const { toggleSideBar } = useLayoutDispatch()
  const { shouldShowSideBar } = useLayoutState()
  const { permissions } = useAuthState()
  const { userInfo } = useUserState()
  const { devices } = useDeviceState()

  const devicesDueTo30DaysReport = filterDevicesDueTo30DaysReport(devices)

  const menu = [
    {
      heading: 'Dashboard',
      menuItems: [
        {
          href: '/home',
          title: 'Dashboard',
          icon: 'fa-tachometer-alt',
          permissions: [],
          defaultShow: false,
        },
        {
          href: '/hourly-usage',
          title: 'Hourly Usage',
          icon: 'fa-tachometer-alt',
          permissions: [],
          defaultShow: false,
        },
        {
          href: '/device-status',
          title: 'Device Status',
          icon: 'fa-rss',
          permissions: ['READ:DEVICE:PROD', 'READ:DEVICE:STATUS:PROD'],
          defaultShow: false,
        },
        {
          href: '/total-spent',
          title: 'Total Spent',
          icon: 'fa-dollar-sign',
          permissions: ['READ:USAGE:COST'],
          defaultShow: false,
        },
        {
          href: '/device-co2',
          title: 'Total Co2 Produced',
          icon: 'fa-cloud',
          permissions: ['ACCOUNT:ADMIN:SMARTFLOW'],
          defaultShow: false,
        },
        {
          href: '/alert-queue',
          title: 'Alert Queue',
          icon: 'fa-exclamation-triangle',
          permissions: ['READ:ALERTS:USAGE'],
          defaultShow: false,
        },
        {
          href: '/customer-journey',
          title: 'Customer Journey',
          icon: 'fa-users',
          permissions: ['ACCOUNT:ADMIN:SMARTFLOW'],
          defaultShow: false,
        },
      ],
    },
    {
      heading: 'Reporting',
      menuItems: [
        {
          href: '/30days',
          title: '30 Days Report',
          icon: 'fa-water',
          permissions: ['READ:REPORTS:30DAY'],
          defaultShow: false,
        },
        {
          href: '/esg-report',
          title: `ESG Report ${
            hasPermissions(permissions, ['ACCOUNT:ADMIN:SMARTFLOW']) &&
            devicesDueTo30DaysReport.length > 0
              ? `(${devicesDueTo30DaysReport.length})`
              : ''
          }`,
          icon: 'fa-water',
          permissions: ['READ:REPORTS:ESG'],
          defaultShow: false,
        },
        {
          href: '/water-usage',
          title: 'Water Usage',
          icon: 'fa-water',
          permissions: ['READ:USAGE'],
          defaultShow: false,
        },
        {
          href: '/historical-alerts',
          title: 'Historical Alerts',
          icon: 'fa-exclamation-triangle',
          permissions: ['READ:ALERTS:USAGE:HISTORY'],
          defaultShow: false,
        },
        {
          href: '/invoice',
          title: 'Invoice',
          icon: 'fa-money-check',
          permissions: ['READ:USAGE:INVOICE'],
          active: !!userInfo.accounts.find((a) => a.invoice),
        },
      ],
    },
    {
      heading: 'Control',
      menuItems: [
        {
          href: '/real-time-flow',
          title: 'Real-Time Flow',
          icon: 'fa-water',
          permissions: ['READ:DEVICE:RTF'],
          defaultShow: false,
        },
        {
          href: '/valves-management',
          title: 'Operate Valves',
          icon: 'fa-faucet',
          permissions: ['DEVICE:VALVE:PROD:OPERATE'],
          defaultShow: false,
        },
      ],
    },
    {
      heading: 'Support Tasks',
      menuItems: [
        {
          href: '/account-management',
          title: 'Account Management',
          icon: 'fa-building',
          permissions: ['UPDATE:ACCOUNT'],
          defaultShow: false,
        },
        // {
        //   href: '/user-management',
        //   title: 'User Management',
        //   icon: 'fa-user',
        //   permissions: ['UPDATE:DEVICE:PROD:USER'],
        //   defaultShow: false,
        // },
        {
          href: '/device-management',
          title: 'Device Management',
          icon: 'fa-cog',
          permissions: ['UPDATE:DEVICE:PROD:USER||UPDATE:DEVICE:PROD:ADMIN'],
          defaultShow: false,
        },
      ],
    },
    {
      heading: 'Test Devices',
      menuItems: [
        {
          href: '/test-water-usage',
          title: 'Water Usage',
          icon: 'fa-water',
          permissions: ['READ:USAGE:TEST'],
          defaultShow: false,
        },
        {
          href: '/test-device-status',
          title: 'Device Status',
          icon: 'fa-rss',
          permissions: ['READ:DEVICE:STATUS:TEST'],
          defaultShow: false,
        },
        {
          href: '/test-valves-management',
          title: 'Operate Valves',
          icon: 'fa-faucet',
          permissions: ['DEVICE:VALVE:TEST:OPERATE'],
          defaultShow: false,
        },
        {
          href: '/test-bench',
          title: 'Test Bench',
          icon: 'fa-flask',
          permissions: ['READ:DEVICE:TEST', 'READ:DEVICE:STGD'],
          defaultShow: false,
        },
      ],
    },
    {
      heading: 'Utilities',
      menuItems: [
        {
          href: '/device-logger',
          title: 'Logger',
          icon: 'fa-history',
          permissions: ['devices:logger:view'],
          defaultShow: false,
        },
      ],
    },
  ]

  return (
    <ul
      style={{ overflowY: 'auto', scrollbarWidth: 'thin', height: '100vh' }}
      className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${
        shouldShowSideBar ? 'toggled' : ''
      }`}
      id="accordionSidebar"
    >
      {/*<ul style={{overflowY:'auto', scrollbarWidth: 'thin', height: '100vh'}} className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${(screenType === 'mobile' || screenType === 'tablet' || screenType === 'laptop') && shouldShowSideBar ? 'toggled' : '' }`} id="accordionSidebar">*/}
      <div style={{ display: 'flex' }}>
        <NavLink
          style={{ margin: 'auto' }}
          onClick={toggleSideBar}
          className="sidebar-brand d-flex align-items-center justify-content-center"
          to="/home"
        >
          <div className="sidebar-brand-icon">
            <img
              alt="Smartflow"
              style={{ width: '100%', height: '30px' }}
              src="/assets/img/logo.svg"
            />
          </div>
        </NavLink>
        {/*{(screenType === 'mobile' || screenType === 'tablet' || screenType === 'laptop') ? <div style={{margin: 'auto'}}><i style={{color: 'white'}} onClick={toggleSideBar} className="fas fa-times"></i></div> : null}*/}
        <div style={{ margin: 'auto', cursor: 'pointer' }}>
          <i style={{ color: 'white' }} onClick={toggleSideBar} className="fas fa-times "></i>
        </div>
      </div>
      {menu.map((menuCategory: any) => {
        const filteredItems = menuCategory.menuItems.filter(
          (mi: { permissions: string[]; defaultShow: boolean }) => {
            // if (mi.permissions.length > 0)
            //    return mi.permissions.filter(p => permissions.findIndex(pp => pp.name === p) >= 0).length > 0
            // return true;
            return mi.permissions.length > 0
              ? hasPermissions(permissions, mi.permissions) || mi.defaultShow
              : true
          },
        )
        return filteredItems.length > 0 ? (
          <SideBarMenuBlock
            key={menuCategory.heading}
            heading={menuCategory.heading}
            type={menuCategory.type}
            menuItems={filteredItems}
          />
        ) : null
      })}
    </ul>
  )
}
export default SideBar
