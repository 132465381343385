import React, { useEffect } from 'react'
import backend from '@api/backend'
import { useAccountDispatch, useAccountState } from '../account/context/account.context'
import Account from '../account/model/Account'
import CustomerLocation from '../account/model/CustomerLocation'
import { useDeviceState } from '../device/context/device.context'
import Device, { TestDevice } from '../device/model/device'
import User, { UserInvite } from '../user/model/user'
import { InviteLocation } from './model/customer-manager'
import { DeviceAdd } from '../location/model/api'
import { Action, ActionTypes, initialState, reducer } from './customer-manager.reducer'

import {
  deleteUserLocationAction,
  updateUserLocationAction,
  addUserLocationAction,
} from './action/location.action'
import { updateUserAccountAction } from './action/account.action'
import { UserLocation } from './model/customer-manager'
import { UserAccountAssoc } from '../user/model/user'
import { displayToast } from '@common/utils/appToast'
import { useAuthState } from '../auth/context/auth.context'
import { compareObjects } from '@common/utils/helperFunctions'

const StateContext = React.createContext(initialState)
const DispatchContext = React.createContext(undefined as any)

const loadUsersAction = async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING })
    const users = await backend.get(`/users?query_type=short`)
    dispatch({ type: ActionTypes.INIT_USERS, payload: { users: users.data } })
  } catch (e) {
    dispatch({ type: ActionTypes.ERROR })
  }
}

const loadGroupsAction = async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING })
    const groups = await backend.get(`/groups`)
    dispatch({ type: ActionTypes.INIT_GROUPS, payload: { groups: groups.data } })
  } catch (e) {
    dispatch({ type: ActionTypes.ERROR })
  }
}

const loadRolesAction = async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING })
    const roles = await backend.get(`/roles`)
    dispatch({ type: ActionTypes.INIT_ROLES, payload: { roles: roles.data } })
  } catch (e) {
    dispatch({ type: ActionTypes.ERROR })
  }
}

const loadPermissionsAction = async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING })
    const permissions = await backend.get(`/permissions`)
    dispatch({ type: ActionTypes.INIT_PERMISSIONS, payload: { permissions: permissions.data } })
  } catch (e) {
    dispatch({ type: ActionTypes.ERROR })
  }
}

export const CustomerManagerContextProvider = ({ children }: any) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const { accounts } = useAccountState()
  const { allDevices } = useDeviceState()

  useEffect(() => {
    dispatch({ type: ActionTypes.INIT_ACCOUNTS, payload: { accounts } })
  }, [accounts])

  useEffect(() => {
    dispatch({ type: ActionTypes.INIT_DEVICES, payload: { devices: allDevices } })
  }, [allDevices])

  useEffect(() => {
    const fetchData = async () => {
      await loadPermissionsAction(dispatch)
      await loadRolesAction(dispatch)
      await loadGroupsAction(dispatch)
      await loadUsersAction(dispatch)
    }
    fetchData().catch(console.error)
  }, [dispatch])

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export const useCustomerManagerState = () => {
  return React.useContext(StateContext)
}

export const useCustomerManagerDispatch = () => {
  const dispatch = React.useContext(DispatchContext) as (action: Action) => any
  const Accounts = useAccountDispatch()
  const { permissions } = useAuthState()

  if (dispatch === undefined) {
    throw new Error(
      'useCustomerManagerDispatch must be used within a CustomerManagerContextProvider',
    )
  }

  const loadUsersByAccount = React.useCallback(
    async (account: Account) => {
      try {
        const users = (await backend.get(`/accounts/${account.id}/users`)).data
        dispatch({ type: ActionTypes.INIT_ACCOUNT_USERS, payload: { users, account } })
      } catch (e: any) {
        dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: e.response.data } })
      }
    },
    [dispatch],
  )

  const loadLocationsByAccount = React.useCallback(
    async (account: Account) => {
      try {
        const locations = (await backend.get(`/accounts/${account.id}/customer_locations`)).data
        dispatch({ type: ActionTypes.INIT_ACCOUNT_LOCATIONS, payload: { locations, account } })
      } catch (e: any) {
        dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: e.response.data } })
      }
    },
    [dispatch],
  )

  const loadInvitationsByAccount = React.useCallback(
    async (account: Account) => {
      try {
        const invitations = (await backend.get(`/accounts/${account.id}/invite_user`)).data
        dispatch({ type: ActionTypes.INIT_INVITATIONS_LOADING, payload: { invitations, account } })
      } catch (e: any) {
        dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: e.response.data } })
      }
    },
    [dispatch],
  )

  const inviteUser = React.useCallback(
    async (
      user: User,
      account: Account,
      locations: InviteLocation[],
      welcomeEmail: boolean,
      autoAddLocations: boolean,
      isAdmin: boolean,
      autoAddAlerts: boolean,
      accountInvoice: boolean,
    ) => {
      try {
        const data: any = {
          email: user.email,
          group_ids: user.groups,
          account_id: {
            account_id: account.id,
            admin: isAdmin,
            auto_add_locations: autoAddLocations,
            auto_add_alerts: autoAddAlerts,
            invoice: accountInvoice,
          },
          welcome_email: welcomeEmail,
        }
        if (locations.length !== 0) {
          data['location_ids'] = locations.map((l) => {
            return { location_id: l.locationId, admin: l.admin, alerts: l.alerts }
          })
        }
        await backend.post('/users/invite_user', data)
        dispatch({ type: ActionTypes.USER_INVITED, payload: { user, accounts: [account] } })
        displayToast({
          type: 'success',
          message: 'User invited successfully',
        })
      } catch (e: any) {
        dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: e.response.data } })
        // displayToast({
        //   type: 'error',
        //   message: e?.response?.data?.detail || 'Something went wrong',
        // })
      }
    },
    [dispatch],
  )

  const updateUserInvite = React.useCallback(
    async (invitation: UserInvite, account: Account) => {
      const data = {
        active: invitation.active,
        accounts: invitation.accounts.map((a) => ({
          account_id_ref: a.accountId,
          invitation_id_ref: a.invitationIdRef,
          admin: a.admin,
          auto_add_alerts: a.autoAddAlerts,
          auto_add_locations: a.autoAddLocations,
          invoice: a.invoice,
        })),
        welcome_email: invitation.welcomeEmail,
        locations: invitation.locations?.map((l) => ({
          location_id_ref: l.locationId,
          admin: l.admin,
          alerts: l.alerts,
        })),
        group_ids: invitation.groupIds,
      }
      try {
        const resp = await backend.patch(`/users/invite_user/${invitation.id}`, data)
        dispatch({
          type: ActionTypes.INVITATION_UPDATED,
          payload: { invitation: resp.data, account: account },
        })
        displayToast({
          type: 'success',
          message: 'User invitation updated successfully',
        })
      } catch (e: any) {
        dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: e.response.data } })
        // displayToast({
        //   type: 'error',
        //   message: e?.response?.data?.detail || 'Something went wrong',
        // })
      }
    },
    [dispatch],
  )

  const resendUserInvite = React.useCallback(
    async (invitationId: number) => {
      try {
        const resp = await backend.put(`/users/resend_invite/${invitationId}`)
        dispatch({ type: ActionTypes.INVITATION_UPDATED, payload: { invitation: resp.data } })
        displayToast({
          type: 'success',
          message: 'Invitation resent successfully',
        })
      } catch (e: any) {
        dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: e.response.data } })
        // displayToast({
        //   type: 'error',
        //   message: e?.response?.data?.detail || 'Something went wrong',
        // })
      }
    },
    [dispatch],
  )

  const createUser = React.useCallback(
    async (user: User, accounts: Account[], autoAddLocations?: boolean, isAdmin?: boolean) => {
      try {
        await backend.post(`/users`, {
          active: true,
          email: user.email,
          first_name: user.firstname,
          last_name: user.lastname,
          mobile: user.mobile,
          groups: user.groups,
          account_ids: accounts.map((a) => {
            return {
              account_id: parseInt(a.id),
              admin: !!isAdmin,
              auto_add_locations: !!autoAddLocations,
            }
          }),
        })
        dispatch({ type: ActionTypes.USER_ADDED, payload: { user, accounts } })
      } catch (e: any) {
        dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: e.response.data } })
      }
    },
    [dispatch],
  )

  const updateUser = React.useCallback(
    async (user: User) => {
      try {
        const response = await backend.patch(`/users/${user.id}`, {
          first_name: user.firstname,
          last_name: user.lastname,
          email: user.email,
          mobile: user.mobile,
          active: user.active,
        })
        dispatch({ type: ActionTypes.USER_UPDATED, payload: { updatedUser: response.data } })
        displayToast({
          type: 'success',
          message: 'User updated successfully',
        })
      } catch (e: any) {
        dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: e.response.data } })
        // displayToast({
        //   type: 'error',
        //   message: e?.response?.data?.detail || 'Something went wrong',
        // })
      }
    },
    [dispatch],
  )

  const deleteUser = React.useCallback(async (user: User) => console.log('deleted user ', user), [])

  const updateAccount = React.useCallback(
    async (account: Account, updatedAccountObj: Partial<Account>) => {
      try {
        await backend.patch(`/accounts/${account.id}`, {
          active: updatedAccountObj.isActive,
          account_name: updatedAccountObj.name,
          address_line_1: updatedAccountObj.addressLine1,
          address_line_2: updatedAccountObj.addressLine2,
          town: updatedAccountObj.town,
          postcode: updatedAccountObj.postcode,
          county: updatedAccountObj.county,
          country: updatedAccountObj.country,
          phone_no: updatedAccountObj.phone,
          account_alias: updatedAccountObj.accountAlias,
          ex_summary_enabled: updatedAccountObj.exSummaryEnabled,
        })
        Accounts.updateAccount(updatedAccountObj)
        dispatch({ type: ActionTypes.ACCOUNT_UPDATED, payload: updatedAccountObj })
        displayToast({
          type: 'success',
          message: 'Account updated successfully',
        })
        const accountUpdates = compareObjects(account, updatedAccountObj)
        // await createComment(account.id, ['Account Update: ', ...accountUpdates], true)
      } catch (e: any) {
        dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: e.response.data } })
        // displayToast({
        //   type: 'error',
        //   message: e?.response?.data?.detail || 'Something went wrong',
        // })
      }
    },
    [dispatch, Accounts],
  )

  const createAccount = React.useCallback(
    async (account: Account) => {
      try {
        const resp = await backend.post(`/accounts`, {
          active: true,
          account_name: account.name,
          address_line_1: account.addressLine1,
          address_line_2: account.addressLine2,
          town: account.town,
          postcode: account.postcode,
          county: account.county,
          country: account.country,
          phone_no: account.phone,
        })
        const newAccount: any = { ...account }
        newAccount.id = `${String(resp.data.id)}`
        Accounts.addAccount(newAccount)
        dispatch({ type: ActionTypes.ACCOUNT_ADDED, payload: { newAccount } })
        displayToast({
          type: 'success',
          message: 'Account created successfully',
        })
      } catch (e: any) {
        dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: e.response.data } })
        // displayToast({
        //   type: 'error',
        //   message: e?.response?.data?.message || 'Something went wrong',
        // })
      }
    },
    [Accounts, dispatch],
  )

  const deleteAccount = React.useCallback(
    async (account: Account) => {
      try {
        await backend.delete(`/accounts/${account.id}`)
        Accounts.deleteAccount(account)
      } catch (e: any) {
        dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: e.response.data } })
      }
    },
    [dispatch, Accounts],
  )

  const addUserToAccounts = React.useCallback(
    async (user: User, accounts: string[], autoAddLocations?: boolean, isAdmin?: boolean) => {
      try {
        await backend.patch(
          `/users/${user.id}/accounts`,
          accounts.map((a) => {
            return {
              account_id: parseInt(a),
              admin: !!isAdmin,
              auto_add_locations: !!autoAddLocations,
            }
          }),
        )
        dispatch({ type: ActionTypes.USER_ADDED, payload: { user, accountIds: accounts } })
      } catch (e: any) {
        dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: e.response.data } })
      }
    },
    [dispatch],
  )

  const removeUserFromAccount = React.useCallback(
    async (user: User, accountId: string) => {
      try {
        await backend.delete(`/accounts/${accountId}/users`, {
          data: { ids: [parseInt(user.id)] },
        })
        dispatch({ type: ActionTypes.USER_REMOVED, payload: { user, accountId } })
      } catch (e: any) {
        dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: e.response.data } })
      }
    },
    [dispatch],
  )

  const addUserToGroups = React.useCallback(
    async (user: User, groupIds: string[]) => {
      try {
        await backend.patch(`/users/${user.id}/groups`, { ids: [...groupIds] })
        //dispatch({ type: ActionTypes.USER_ADDED, payload: { user } })
      } catch (e: any) {
        dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: e.response.data } })
      }
    },
    [dispatch],
  )

  const removeUserFromGroups = React.useCallback(
    async (user: User, groupIds: string[]) => {
      try {
        await backend.delete(`/users/${user.id}/groups`, {
          data: { ids: [...groupIds] },
        })
        //dispatch({ type: ActionTypes.GROU, payload: { user, } })
      } catch (e: any) {
        dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: e.response.data } })
      }
    },
    [dispatch],
  )

  const removeLocationFromAccount = React.useCallback(
    async (location: CustomerLocation, account: Account) => {
      try {
        await backend.delete(`/customer_locations/${location.id}`)
        dispatch({ type: ActionTypes.LOCATION_REMOVED, payload: { location, account } })
        displayToast({
          type: 'success',
          message: 'Location deleted successfully',
        })
      } catch (e: any) {
        dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: e.response.data } })
      }
    },
    [dispatch],
  )

  const addDeviceToLocation = React.useCallback(
    async (device: Device) => {
      try {
        const payload: DeviceAdd = {
          device_id: device.deviceId,
          device_name: device.deviceName,
          location: device.deviceSettings.location,
          data_acq_mode: 0,
          server_pulse_control: true,
          pulses_per_liter: device.deviceSettings.pulsesPerLiter,
          timezone: device.deviceSettings.timezone,
          install_date: device.deviceSettings.installDate,
          sector_type: device.deviceSettings.sectorType,
          active: device.deviceSettings.active,
          currency: device.deviceSettings.currency,
          cost: device.deviceSettings.cost,
          hot: device.deviceSettings.hot,
          occupants: device.deviceSettings.occupants,
          upload_freq_mins: device.deviceSettings.uploadFreqMins,
          uom: device.deviceSettings.uom,
        }
        const resp = await backend.patch(
          `/customer_locations/${device.deviceLocationId}/devices`,
          payload,
        )
        dispatch({
          type: ActionTypes.DEVICE_ADDED,
          payload: { deviceData: resp.data, permissions: permissions },
        })
        displayToast({
          type: 'success',
          message: 'Device created successfully',
        })
      } catch (e: any) {
        console.log(e)
        dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: e.response.data.message } })
      }
    },
    [dispatch],
  )

  const addLocationToAccount = React.useCallback(
    async (location: CustomerLocation, account: Account) => {
      try {
        const payload = {
          address_line_1: location.addressLine1,
          address_line_2: location.addressLine2,
          town: location.town,
          county: location.county,
          postcode: location.postcode,
          country: location.country,
          location_name: location.name,
          account_id: account.id,
          company_alias: location.companyAlias,
          active: location.active,
          suspended: location.suspended,
          settings: {
            onboarding_status: location.settings.onboardingStatus,
            onboarding_date: location.settings.onboardingDate,
          },
        }
        let response
        if (!location.id) {
          response = await backend.post('/customer_locations', payload)
          dispatch({
            type: ActionTypes.LOCATION_ADDED,
            payload: { location, account, resp: response.data },
          })
          displayToast({
            type: 'success',
            message: 'Location created successfully',
          })
        } else {
          response = await backend.put(`/customer_locations/${location.id}`, {
            ...payload,
            id: location.id,
            active: location.active,
          })
          dispatch({
            type: ActionTypes.LOCATION_UPDATED,
            payload: { location, account, resp: response.data },
          })
          displayToast({
            type: 'success',
            message: 'Location updated successfully',
          })
        }
      } catch (e: any) {
        dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: e.response.data } })
      }
    },
    [dispatch],
  )

  const removeDeviceFromAccount = React.useCallback(
    async (device: Device, account: Account) => {
      try {
        await backend.delete(`/accounts/${account.id}/devices/${device.deviceId}`)
        dispatch({ type: ActionTypes.DEVICE_REMOVED, payload: { device, account } })
      } catch (e: any) {
        dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: e.response.data } })
      }
    },
    [dispatch],
  )

  const swapDevice = React.useCallback(
    async (origin: Device, destination: TestDevice) => {
      try {
        await backend.put(
          `/device_swap/live_device_swap_out/${origin.deviceId}?new_device=${destination.deviceId}`,
        )
        dispatch({ type: ActionTypes.DEVICE_SWAPPED, payload: { origin, destination } })
        displayToast({
          type: 'success',
          message: 'Device swapped successfully',
        })
      } catch (e: any) {
        dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: e.response.data } })
        // displayToast({
        //   type: 'error',
        //   message: e?.response?.data?.detail || e?.response?.data?.message || 'Something went wrong',
        // })
      }
    },
    [dispatch],
  )

  const loadAlertSubscription = React.useCallback(
    async (userId: string) => {
      try {
        const resp = await backend.get(`/users/${userId}/alert_subs`)
        dispatch({ type: ActionTypes.INIT_USER_ALERT_SUBS_LOADING, payload: { subs: resp.data } })
      } catch (e: any) {
        dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: e.response.data } })
      }
    },
    [dispatch],
  )

  const addAlertSubscription = React.useCallback(
    async (locationId: string, user: User) => {
      try {
        const data: { recipients: [{ [key: string]: string }] } = {
          recipients: [
            {
              id: user.id,
              recipient_type: 'to',
            },
          ],
        }
        dispatch({ type: ActionTypes.USER_SUBS_UPDATING })
        const resp = await backend.post(`/customer_locations/${locationId}/alert_recipients`, data)
        dispatch({
          type: ActionTypes.USER_ALERT_SUBS_UPDATED,
          payload: { locationId: locationId, userId: user.id, resp: resp.data, operation: 'add' },
        })
      } catch (e: any) {
        dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: e.response.data } })
      }
    },
    [dispatch],
  )

  const removeAlertSubscription = React.useCallback(
    async (locationId: string, user: User) => {
      try {
        const data = {
          ids: [user.id],
        }
        dispatch({ type: ActionTypes.USER_SUBS_UPDATING })
        const resp = await backend.delete(`/customer_locations/${locationId}/alert_recipients`, {
          data: data,
        })
        dispatch({
          type: ActionTypes.USER_ALERT_SUBS_UPDATED,
          payload: { locationId: locationId, userId: user.id, operation: 'remove', resp: null },
        })
      } catch (e: any) {
        console.log(e)
        dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: e.response.data } })
      }
    },
    [dispatch],
  )

  const loadUsers = React.useCallback(async () => await loadUsersAction(dispatch), [dispatch])

  const loadGroups = React.useCallback(async () => await loadGroupsAction(dispatch), [dispatch])

  const loadRoles = React.useCallback(async () => await loadRolesAction(dispatch), [dispatch])

  const loadPermissions = React.useCallback(
    async () => await loadPermissionsAction(dispatch),
    [dispatch],
  )

  const deleteUserLocation = React.useCallback(
    async (id: string, locations: UserLocation[]) =>
      await deleteUserLocationAction(id, locations)(dispatch),
    [dispatch],
  )

  const updateUserLocation = React.useCallback(
    async (id: string, locations: UserLocation[]) =>
      await updateUserLocationAction(id, locations)(dispatch),
    [dispatch],
  )

  const addUserLocation = React.useCallback(
    async (id: string, locations: UserLocation[]) =>
      await addUserLocationAction(id, locations)(dispatch),
    [dispatch],
  )

  const updateUserAccount = React.useCallback(
    async (id: string, accountUpdate: UserAccountAssoc[]) =>
      await updateUserAccountAction(id, accountUpdate)(dispatch),
    [dispatch],
  )

  return React.useMemo(
    () => ({
      inviteUser,
      updateUserInvite,
      resendUserInvite,
      loadUsers,
      loadGroups,
      loadRoles,
      loadPermissions,
      loadUsersByAccount,
      loadLocationsByAccount,
      loadInvitationsByAccount,
      updateUser,
      createUser,
      deleteUser,
      updateAccount,
      createAccount,
      deleteAccount,
      addUserToAccounts,
      removeUserFromAccount,
      removeLocationFromAccount,
      addDeviceToLocation,
      addLocationToAccount,
      removeDeviceFromAccount,
      swapDevice,
      addUserToGroups,
      removeUserFromGroups,
      loadAlertSubscription,
      addAlertSubscription,
      removeAlertSubscription,
      deleteUserLocation,
      updateUserLocation,
      addUserLocation,
      updateUserAccount,
    }),
    [
      loadUsersByAccount,
      updateUser,
      createUser,
      deleteUser,
      updateAccount,
      createAccount,
      deleteAccount,
      addUserToAccounts,
      removeUserFromAccount,
      addDeviceToLocation,
      removeDeviceFromAccount,
      swapDevice,
    ],
  )
}
