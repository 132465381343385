import React, { useEffect, useState } from 'react'
import { Col, Form, Modal, Table, Spinner, Button, Row } from 'react-bootstrap'
import { FaTriangleExclamation } from 'react-icons/fa6'
import Account from '@context/account/model/Account'
import CustomerLocation, { AlertSub } from '@context/account/model/CustomerLocation'
import {
  useCustomerManagerDispatch,
  useCustomerManagerState,
} from '@context/customerManager/customer-manager.context'
import User from '@context/user/model/user'

import './Modal.scss'

interface IModalProps {
  show: boolean
  user: User
  account: Account
  onHide: () => void
}

const AlertEditModal: React.FC<IModalProps> = ({ show, account, user, onHide }: IModalProps) => {
  const { locationsByAccount, userAlertSubs, subsUpdating, error, errorMsg } =
    useCustomerManagerState()
  const { loadAlertSubscription, addAlertSubscription, removeAlertSubscription } =
    useCustomerManagerDispatch()

  const [accountLocations, setAccountLocations] = useState<CustomerLocation[]>([])
  const [selectedAlertLocations, setSelectedAlertLocations] = useState<AlertSub[]>([])

  useEffect(() => {
    loadAlertSubscription(user.id)
  }, [user, show, loadAlertSubscription])

  useEffect(() => {
    if (locationsByAccount[account.id]) {
      setAccountLocations(locationsByAccount[account.id])
    }
  }, [locationsByAccount, account])

  useEffect(() => {
    setSelectedAlertLocations(userAlertSubs || [])
  }, [userAlertSubs, show])

  const setAlertLocation = (locationId: string) => {
    const alertLocation = selectedAlertLocations.filter(
      (loc) => loc.locationId.toString() === locationId,
    ).length
    if (alertLocation === 0) {
      addAlertSubscription(locationId, user)
    } else {
      removeAlertSubscription(locationId.toString(), user)
    }
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="modal-header" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{'Alert Subscriptions'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="alert-information-form">
          <Row>
            <Form.Group as={Col} controlId="formGridLocations">
              <Form.Label>
                Select Alert Subscription Locations
                {subsUpdating ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      variant="info"
                      role="status"
                      aria-hidden="true"
                      className={'ml-5 mr-2'}
                    />
                    <span>Updating</span>
                  </>
                ) : null}
                {error && errorMsg ? (
                  <span className={'text-danger'}>
                    <FaTriangleExclamation className={'ml-5 mr-2 text-danger'} /> {errorMsg.message}
                  </span>
                ) : null}
              </Form.Label>
              <div style={{ width: '70%', height: '300px', overflowY: 'auto' }}>
                <Table striped>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {accountLocations.map((l) => {
                      if (user.customerLocationIds.find((clId) => clId === l.id)) {
                        return (
                          <tr key={l.id}>
                            <td>
                              <Form.Check
                                type={'checkbox'}
                                label={l.name}
                                value={l.id}
                                id={`default-${l.id}`}
                                onChange={(e) => setAlertLocation(e.target.value)}
                                className={'ml-2'}
                                checked={
                                  !!selectedAlertLocations.find(
                                    (loc) => loc.locationId.toString() === l.id,
                                  )
                                }
                              />
                            </td>
                          </tr>
                        )
                      } else {
                        return null
                      }
                    })}
                  </tbody>
                </Table>
              </div>
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AlertEditModal
