import Home from './components/main/Home/Home'
import HomeTactical from './components/main/Home/HomeTacticalOverview'
import DeviceStatus from './components/main/DeviceStatus/DeviceStatus'
import TestDeviceStatus from './components/main/TestDevices/DeviceStatus/DeviceStatus'
import RealTimeFlow from './components/main/RealTimeFlow/Dashboard'
import TestBench from './components/main/TestBench/Dashboard'
import WaterUsage from './components/main/WaterUsage/WaterUsage'
import TestWaterUsage from './components/main/TestDevices/WaterUsage/WaterUsage'
import AlertQueue from './components/main/AlertQueue/AlertQueue'
import AlertQueueItem from './components/main/AlertQueue/AlertQueueItem'
import HistoricalAlertView from './components/main/AlertQueue/HistorialAlertView'
import AccountManagement from './components/main/AccountManagement/Dashboard'
import DeviceManagement from './components/main/DeviceManagement/DeviceManagement'
import UserManagement from './components/main/UserManagement/UserManagement'
import ValvesManagement from './components/main/ValvesManagement/ValvesManagement'
import TestValvesManagement from './components/main/TestDevices/ValvesManagement/ValvesManagement'
import UserProfile from './components/main/User/UserProfile'
import Report30Days from './components/main/Reports/30DaysReport'
import { DeviceCostComponent } from './components/main/DeviceCost/DeviceCostComponent'
import { DeviceCo2Component } from './components/main/DeviceCo2/DeviceCo2Component'
import ESGReport from './components/main/Reports/ESGReport'
import Invoice from './components/main/Reports/Invoice'
import CustomerJourney from './components/main/DeviceManagement/CustomerJourney'

export interface RoutedComponent {
  path: string
  children?: RoutedComponent[]
  component: React.FC
  permissions: string[]
}

const routes: RoutedComponent[] = [
  {
    path: '/water-usage',
    component: WaterUsage,
    permissions: ['READ:USAGE'],
  },
  {
    path: '/test-water-usage',
    component: TestWaterUsage,
    permissions: ['READ:USAGE:TEST'],
  },
  {
    path: '/alert-queue',
    component: AlertQueue,
    permissions: ['READ:ALERTS:USAGE'],
    children: [
      {
        path: ':id',
        component: AlertQueueItem,
        permissions: ['READ:ALERTS:USAGE'],
      },
    ],
  },
  {
    path: '/total-spent',
    component: DeviceCostComponent,
    permissions: ['READ:USAGE:COST'],
  },
  {
    path: '/device-co2',
    component: DeviceCo2Component,
    permissions: ['ACCOUNT:ADMIN:SMARTFLOW'],
  },
  {
    path: '/historical-alerts',
    component: HistoricalAlertView,
    permissions: ['READ:ALERTS:USAGE:HISTORY'],
  },
  {
    path: '/device-status',
    component: DeviceStatus,
    permissions: ['READ:DEVICE:PROD', 'READ:DEVICE:STATUS:PROD'],
  },
  {
    path: '/test-device-status',
    component: TestDeviceStatus,
    permissions: ['READ:DEVICE:STATUS:TEST'],
  },
  {
    path: '/account-management',
    component: AccountManagement,
    permissions: ['READ:ACCOUNT'],
  },
  {
    path: '/valves-management',
    component: ValvesManagement,
    permissions: ['DEVICE:VALVE:PROD:OPERATE'],
  },
  {
    path: '/test-valves-management',
    component: TestValvesManagement,
    permissions: ['DEVICE:VALVE:TEST:OPERATE'],
  },
  {
    path: '/test-bench',
    component: TestBench,
    permissions: ['READ:DEVICE:TEST', 'READ:DEVICE:STGD'],
  },
  {
    path: '/real-time-flow',
    component: RealTimeFlow,
    permissions: ['READ:DEVICE:RTF'],
  },
  {
    path: '/user-management',
    component: UserManagement,
    // permissions: ["UPDATE:DEVICE:PROD:USER"]
    permissions: ['UPDATE:DEVICE:PROD:USER||UPDATE:DEVICE:PROD:ADMIN'],
  },
  {
    path: '/device-management',
    component: DeviceManagement,
    // permissions: ["UPDATE:DEVICE:PROD:USER"]
    permissions: ['UPDATE:DEVICE:PROD:USER||UPDATE:DEVICE:PROD:ADMIN'],
  },
  {
    path: '/user-profile',
    component: UserProfile,
    permissions: [],
  },
  {
    path: '/invoice',
    component: Invoice,
    permissions: ['READ:USAGE:INVOICE'],
  },
  {
    path: '/30days',
    component: Report30Days,
    permissions: ['READ:REPORTS:30DAY'],
  },
  {
    path: '/esg-report',
    component: ESGReport,
    permissions: ['READ:REPORTS:ESG'],
  },
  {
    path: '/hourly-usage',
    component: Home,
    permissions: [],
  },
  {
    path: '/customer-journey',
    component: CustomerJourney,
    permissions: ['ACCOUNT:ADMIN:SMARTFLOW'],
  },
  {
    path: '/home',
    component: HomeTactical,
    permissions: [],
  },
]

export default routes
