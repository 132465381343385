import Select, { components } from 'react-select'
import { Form } from 'react-bootstrap'
import { useAccountState } from '@context/account/context/account.context'
import { useLocationDispatch, useLocationState } from '@context/location/context/location.context'
import Account from '@context/account/model/Account'
import CustomerLocation from '@context/account/model/CustomerLocation'

interface LocationOptions {
  label: string
  options: {
    id: string
    name: string
    accountId: string
    active: boolean
  }[]
}

const ValueContainer = (containerProps: any) => {
  const options: LocationOptions[] = containerProps.options
  const totalOptions = options.reduce((acc, obj) => acc + obj.options.length, 0)
  return (
    <components.ValueContainer {...containerProps} className={'d-flex'}>
      Locations ({containerProps?.children[0]?.length ? containerProps.children[0].length : 0} /
      {totalOptions}){containerProps.children[1]}
    </components.ValueContainer>
  )
}

const Option = (containerProps: any) => {
  return (
    <components.Option {...containerProps}>
      <Form.Check checked={containerProps.isSelected} readOnly label={containerProps.children} />
    </components.Option>
  )
}

const LocationSelector = () => {
  const { selectedAccounts } = useAccountState()
  const { locations, selectedLocations, loading } = useLocationState()
  const { setSelectedLocations } = useLocationDispatch()

  const optionStyles = (styles: any, { isFocused }: any) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#eee' : null,
      color: '#333',
    }
  }

  const customLocations = selectedAccounts.map((account: Account) => {
    return {
      label: account.name,
      options: locations.filter((location: CustomerLocation) => location.accountId === account.id),
    }
  })

  const MenuList = (props: any) => {
    return (
      <components.MenuList {...props}>
        {selectedLocations.length > 0 ? (
          <button className="btn btn-link" onClick={() => setSelectedLocations([])}>
            deselect all locations
          </button>
        ) : (
          <button className="btn btn-link" onClick={() => setSelectedLocations(locations)}>
            select all locations
          </button>
        )}
        {props.children}
      </components.MenuList>
    )
  }

  return (
    locations.length > 1 && (
      <div className="multiselect-container">
        <Select
          styles={{ option: optionStyles }}
          components={{ MenuList, ValueContainer, Option }}
          className="basic-multi-select "
          value={selectedLocations}
          onChange={(options: any) => setSelectedLocations([...options])}
          options={customLocations}
          isMulti
          name="devices"
          classNamePrefix="select"
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => `${option.name}`}
          isClearable={false}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          isLoading={loading}
          isDisabled={loading || locations.length === 0 || selectedAccounts.length === 0}
        />
      </div>
    )
  )
}

export default LocationSelector
