import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import moment from 'moment/moment'

import Account from '@context/account/model/Account'
import CustomerLocation from '@context/account/model/CustomerLocation'
import { useCustomerManagerDispatch } from '@context/customerManager/customer-manager.context'
import { useDeviceState } from '@context/device/context/device.context'
import Device, { TestDevice } from '@context/device/model/device'
import {
  CURRENCY_OPTIONS,
  SECTOR_TYPES_OPTIONS,
  UPLOAD_FREQ_MINS,
  OptionsMap,
  UOM_OPTIONS,
} from '../../DeviceManagement/DeviceInformationManager/constants'

import { pulseOptions } from '@common/utils/helperFunctions'

import './Modal.scss'

interface IModalProps {
  show: boolean
  account: Account
  customerLocation: CustomerLocation
  onHide: () => void
}

const AddDeviceModal: React.FC<IModalProps> = ({
  show,
  account,
  customerLocation,
  onHide,
}: IModalProps) => {
  const [selectedDevice, setSelectedDevice] = useState<TestDevice>()
  const { stagedDevices, timezones } = useDeviceState()

  const [newDeviceName, setNewDeviceName] = useState('')
  const [newPulses, setNewPulses] = useState(0)
  const [newCost, setNewCost] = useState(2.2)
  const [newDeviceLocationRef, setNewDeviceLocationRef] = useState('')
  const [newDeviceSectorType, setNewSectorType] = useState('')
  const [newWaterType, setNewWaterType] = useState<string>('Cold')
  const [newDeviceActive, setNewDeviceActive] = useState(false)
  const [newTimezone, setNewTimezone] = useState<string>('')
  const [newCurrency, setNewCurrency] = useState('')
  const [newInstallDate, setInstallDate] = useState<Date | null>(moment().clone().toDate())
  const [newOccupants, setNewOccupants] = useState<number>(0)
  const [newUploadFreqMins, setNewUploadFreqMins] = useState<string>('')
  const [newUOM, setNewUom] = useState<string>('')

  const { addDeviceToLocation } = useCustomerManagerDispatch()
  let required =
    !selectedDevice ||
    newDeviceName.length === 0 ||
    newCost === 0 ||
    isNaN(newCost) ||
    newDeviceSectorType === '' ||
    newPulses === 0 ||
    newUploadFreqMins.length === 0 ||
    newUOM === ''

  useEffect(() => {
    if (show) {
      setNewDeviceName('')
      setNewDeviceLocationRef('')
      setNewPulses(0)
      setNewSectorType('')
      setNewOccupants(0)
      setNewCurrency(OptionsMap[customerLocation.country].currency || '')
      setNewTimezone(OptionsMap[customerLocation.country].timezone || '')
      setNewUom(OptionsMap[customerLocation.country].uom || '')
    }
  }, [show])

  function handleSave() {
    if (!selectedDevice) return

    const updatedDevice: Device = {
      ...selectedDevice,
      accountId: account.id,
      batteryLevel: 0,
      batteryLevelExt: 0,
      batteryStatus: '',
      childDeviceIds: [],
      connectionStatus: 'Disconnected',
      signal: 0,
      deviceName: newDeviceName,
      valves: {
        V1: { key: 'V1', state: 7, name: 'Valve #1' },
        V2: { key: 'V2', state: 7, name: 'Valve #2' },
        V3: { key: 'V3', state: 7, name: 'Valve #3' },
        V4: { key: 'V4', state: 7, name: 'Valve #4' },
        V5: { key: 'V5', state: 7, name: 'Valve #5' },
        V6: { key: 'V6', state: 7, name: 'Valve #6' },
      },
      deviceSettings: {
        ...selectedDevice.deviceSettings!,
        cost: newCost,
        sectorType: newDeviceSectorType,
        pulsesPerLiter: newPulses,
        hot: newWaterType === 'Hot',
        type: newWaterType,
        active: newDeviceActive,
        installDate: moment(newInstallDate).utc().format('YYYY-MM-DDTHH:00:00'),
        occupants: newOccupants,
        timezone: newTimezone,
        currency: newCurrency,
        uploadFreqMins: newUploadFreqMins,
        uom: newUOM,
        location: newDeviceLocationRef,
      },
      deviceLocationId: customerLocation?.id!,
    }

    addDeviceToLocation(updatedDevice)
    onHide()
  }

  const installDateChange = (date: Date | null) => {
    setInstallDate(date)
  }
  return (
    <Modal
      onHide={onHide}
      show={show}
      backdrop="static"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="device-information-modal"
    >
      <Modal.Header className="modal-header" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add New Device to Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {required ? <span className="red">* Required!!</span> : ''}
        <Form className="device-information-form">
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridName">
              <Form.Label>
                Staged Device: <span className="red">{!selectedDevice ? '*' : ''}</span>
              </Form.Label>
              <Select
                classNamePrefix="select"
                value={selectedDevice}
                onChange={(d: any) => setSelectedDevice(d)}
                options={stagedDevices}
                getOptionValue={(option) => option.deviceId}
                getOptionLabel={(option) => `${option.deviceName}`}
                isClearable={false}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridName">
              <Form.Label>
                Name <span className="red">{newDeviceName.length === 0 ? '*' : ''}</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter a name"
                value={newDeviceName}
                onChange={(e) => setNewDeviceName(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridName">
              <Form.Label>Location Reference</Form.Label>
              <Form.Control
                type="text"
                placeholder="Pole B12"
                value={newDeviceLocationRef}
                onChange={(e) => setNewDeviceLocationRef(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridUOM">
              <Form.Label>
                Unit Of Measurement <span className="red">{!newUOM ? '*' : ''}</span>
              </Form.Label>
              <Select
                classNamePrefix="select"
                value={{
                  value: newUOM,
                  label: newUOM,
                }}
                onChange={(e: any) => setNewUom(e.value)}
                options={UOM_OPTIONS.map((uom) => {
                  return { value: uom, label: uom }
                })}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => `${option.label}`}
                isClearable={false}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPulses">
              <Form.Label>
                Pulses <span className="red">{!newPulses ? '*' : ''}</span>
              </Form.Label>
              <Select
                classNamePrefix="select"
                onChange={(e: any) => setNewPulses(Number(e.value))}
                options={newUOM ? pulseOptions(newUOM) : []}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => `${option.label}`}
                isClearable={false}
                isDisabled={!newUOM}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridCost">
              <Form.Label>
                Cost <span className="red">{newCost === 0 || isNaN(newCost) ? '*' : ''}</span>
              </Form.Label>
              <Form.Control
                type="number"
                placeholder="e.g. 1.93"
                min={0}
                value={newCost}
                onChange={(e) => setNewCost(parseFloat(e.target.value))}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridSpacer"></Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formSectorType">
              <Form.Label>
                Sector <span className="red">{!newDeviceSectorType ? '*' : ''}</span>
              </Form.Label>
              <Select
                classNamePrefix="select"
                onChange={(d: any) => setNewSectorType(d.value)}
                options={SECTOR_TYPES_OPTIONS.map((sector) => {
                  return { value: sector, label: sector }
                })}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => `${option.label}`}
                isClearable={false}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formSectorType">
              <Form.Label>Water Type </Form.Label>
              <Select
                classNamePrefix="select"
                defaultValue={{
                  value: newWaterType,
                  label: newWaterType,
                }}
                onChange={(d: any) => setNewWaterType(d.value)}
                options={['Cold', 'Hot'].map((wType) => {
                  return { value: wType, label: wType }
                })}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => `${option.label}`}
                isClearable={false}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group
              as={Col}
              controlId="formDeviceActive"
              className="d-flex align-items-center mt-4"
            >
              <Form.Check
                type="checkbox"
                inline
                onChange={() => setNewDeviceActive(!newDeviceActive)}
                checked={newDeviceActive}
                label="Device Active"
                id={`inline-device-active`}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridOccupants">
              <Form.Label>Occupants</Form.Label>
              <Form.Control
                type="number"
                value={newOccupants}
                min={0}
                onChange={(e) => setNewOccupants(parseInt(e.target.value))}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formTimezone">
              <Form.Label>Timezone</Form.Label>
              <Select
                classNamePrefix="select"
                value={{
                  value: newTimezone,
                  label: newTimezone,
                }}
                onChange={(d: any) => setNewTimezone(d.value)}
                options={timezones.map((timezone) => {
                  return { value: timezone.name, label: timezone.name }
                })}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => `${option.label}`}
                isClearable={false}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridCurrency">
              <Form.Label>Currency</Form.Label>
              <Select
                classNamePrefix="select"
                value={{
                  value: newCurrency,
                  label: newCurrency,
                }}
                onChange={(d: any) => setNewCurrency(d.value)}
                options={CURRENCY_OPTIONS.map((currency) => {
                  return { value: currency, label: currency }
                })}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => `${option.label}`}
                isClearable={false}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="formInstallDate">
              <Form.Label>Install Date</Form.Label>
              <div>
                <DatePicker
                  selected={newInstallDate}
                  onChange={installDateChange}
                  placeholderText="Select Install date"
                  showTimeSelect
                  dateFormat="dd/MM/yyyy HH:00"
                  timeFormat="HH:mm"
                />
              </div>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridUploadFreq">
              <Form.Label>
                Upload Frequency (hours)
                <span className="red">{newUploadFreqMins.length === 0 ? '*' : ''}</span>
              </Form.Label>
              <Select
                classNamePrefix="select"
                onChange={(d: any) => setNewUploadFreqMins(d.value)}
                options={UPLOAD_FREQ_MINS.map((freq) => {
                  return { value: freq, label: freq }
                })}
                getOptionValue={(option) => `${option.value}`}
                getOptionLabel={(option) => `${parseInt(option.label) / 60}`}
                isClearable={false}
              />
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button disabled={required} onClick={() => handleSave()}>
          Activate Device
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddDeviceModal
