import { toast } from 'react-toastify'

type ToastTypes = 'default' | 'error' | 'info' | 'success' | 'warning'

type ToastPosition =
  | 'bottom-left'
  | 'bottom-center'
  | 'bottom-right'
  | 'top-left'
  | 'top-center'
  | 'top-right'

interface ToastProps {
  type: ToastTypes
  message: string
  position?: ToastPosition
  id?: string
}

export const displayToast = ({ type, message, position = 'bottom-left', id }: ToastProps) => {
  toast(message, {
    position,
    type,
    toastId: id,
  })
}
